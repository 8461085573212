export default {
  slug: "flag-quiz",
  theme: {
    iconColor: "#26A7F7",
    iconKey: "flag-quiz",
    shadow: "0 3px rgba(0, 104, 169, 0.2)",
    elevationColor: "rgba(0, 123, 236, 0.25)",
    background: "rgb(61, 173, 243)",
    text: {
      400: "rgb(255, 255, 255)",
      500: "rgb(255, 255, 255)",
    },
  },
};
