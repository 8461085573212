export const locales = [
  {
    code: "en",
    iso: "en-US",
    name: "English",
  },
  {
    code: "de",
    iso: "de-DE",
    name: "Deutsch",
  },
];

export const localeDefault = locales[0];
